import React from 'react';
import { Seo } from 'components';
import Layout from 'layout';
import { Row, Col } from 'react-flexbox-grid';
import useAxios from 'axios-hooks';
import cardMed from '../images/card_medication.png';
import cardAdmin from '../images/card_admin.png';
// import page style
import './index.scss';

const IndexPage = () => {
  /**
   * Remove all the HTML and CSS contents from this page to use your own.
   * All the contents in this page is just for the demonstration purpose.
   * You may use React Flexible Grid or any other grid of your choice
   * The following styles should be moved into the page specific scss file.
   */

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: '/.netlify/functions/savings-card',
    },
    { manual: true },
  );
  return (
    <Layout indication="patient" className="patient-home">
      <Seo pageTitle="Patient Home" />
      <div className="body-content">
        <section className="saving-block">
          <div className="wrapper">
            <div className="centered-content">
              <div className="saving-block__content">
                <h2>Start Here</h2>
                <p>
                  In order to enroll for savings, each patient must first have a
                  unique ID number. Use the generator below to create an ID for
                  each patient. Each time you click the button, a new ID will be
                  generated.
                </p>
                <p>
                  <strong>
                    Note: It is the responsibility of the Specialty Pharmacy to
                    ensure that patients meet all program eligibility
                    requirements as listed below
                  </strong>
                  .
                </p>
                <Row className="card-wrap">
                  <Col md={6} className="col-1">
                    <div className="card-info">
                      <span className="top-bg" />
                      <div className="card-info__content">
                        <h3>Generate​ a Unique Patient ID</h3>
                        <div className="btn-wrap">
                          <a href="#" className="button" onClick={refetch}>
                            Get Next ID
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} className="col-2">
                    <div className="card-info card-info--alt">
                      <span className="top-bg" />
                      <div className="card-info__content">
                        <h3>Unique Patient ID</h3>
                        <div className="inner-content">
                          <h4>QUTENZA Patient Savings ID:</h4>
                          {loading && 'loading...'}
                          {error && 'error loading savings information.'}
                          {!loading && data && (
                            <div className="savings-info-container">
                              <p>RxBIN: {data.data.savings?.BinNumber[0]}</p>
                              <p>RxPCN: {data.data.savings?.RxPCN[0]}</p>
                              <p>RxGRP: {data.data.savings?.GroupNumber[0]}</p>
                              <p>
                                RxID: {data.data.savings?.DocumentNumber[0]}
                              </p>
                            </div>
                          )}
                          {!loading && !data && (
                            <div className="savings-info-container">
                              <p>RxBIN: XXXXXX</p>
                              <p>RxPCN: XXXX</p>
                              <p>RxGRP: XXXXXXXXX</p>
                              <p>RxID: XXXXXXXXXXXX</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="wave-bg" />
        </section>

        <section className="pricing-block">
          <div className="centered-content">
            <h4 className="md-text">
              Put patients on the path to saving today. ​My QUTENZA Connect
              offers 2 ways to save.
            </h4>
            <Row className="card-pricing__wrap">
              <Col md={6} className="col">
                <img src={cardMed} alt="Medication Savings" />
              </Col>
              <Col md={6} className="col">
                <img src={cardAdmin} alt="Administration Savings" />
              </Col>
            </Row>
          </div>
        </section>

        <section className="main-content">
          <div className="centered-content">
            <Row>
              <Col xs={12}>
                <p className="title small-pad-bottom">
                  <strong>
                    Patients may be eligible for the cost savings program if
                    they:
                  </strong>
                </p>
                <ul className="dot-list">
                  <li>Are using QUTENZA for an FDA-approved use</li>
                  <li>Are 18 years of age or older</li>
                  <li>
                    Have commercial (private) insurance that covers QUTENZA
                  </li>
                  <li>Live and receive treatment in the United States</li>
                  <li>
                    Do not use a state or federal healthcare plan to pay for
                    their medication – this includes, but is not limited to,
                    Medicare, Medicaid, and TRICARE
                  </li>
                </ul>
                <p className="title small-pad-bottom">
                  <strong>Benefit:</strong>
                </p>
                <ul className="dot-list">
                  <li>
                    Eligible patients may pay as little as $0, with a maximum
                    annual benefit of $5,000
                  </li>
                  <li>
                    Eligible patients may receive up to $1,500 per year for
                    in-office administration costs
                  </li>
                </ul>
                <p className="title small-pad-bottom">
                  <strong>Questions:</strong>
                </p>
                <ul className="dot-list">
                  <li>
                    For questions regarding the My QUTENZA Connect Cost Savings
                    Program, please call 1-833-295-3579
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p className="title txt-indent">
                  <strong>*Eligibility Criteria, Terms, and Conditions:</strong>
                </p>
                <span>
                  By using this offer, you confirm that you currently meet all
                  eligibility criteria and will comply with all terms and
                  conditions, as described below:
                </span>
                <ol className="eligibility-list">
                  <li>
                    The My QUTENZA Connect Cost Savings Program (the “Program”)
                    is available only to eligible adult patients prescribed
                    QUTENZA for use consistent with approved indications in US
                    product labeling for QUTENZA. Eligible patients must reside
                    in the US, Puerto Rico, or the US territories based on the
                    patient’s address and must be insured by a commercial
                    insurer that covers QUTENZA and does not prohibit
                    participation in patient assistance programs. Uninsured or
                    cash-paying patients and patients with coverage for QUTENZA
                    through federal- or state-funded government healthcare
                    programs, including Medicare, Medicaid, Medigap, TRICARE,
                    Veterans Affairs (VA), or Department of Defense (DoD), are
                    not eligible for the Program, with the exception of the
                    Federal Employees Health Beneﬁt (FEHB) Program for the
                    purpose of this Program. A patient who begins receiving
                    benefits for QUTENZA from a government healthcare program
                    will no longer be eligible for the Program.
                  </li>
                  <li>
                    This Program will only accept applications by mail. No phone
                    or email requests will be accepted or honored. Applications
                    must be fully completed based on the instructions stated on
                    the registration form. Averitas Pharma, Inc., is not
                    responsible for lost, late, damaged, misdirected,
                    incomplete, or illegible submissions. All submissions become
                    the property of Averitas Pharma, Inc., and its agents.
                    Please retain copies of any materials you submit.
                  </li>
                  <li>
                    Any refund under this Program may not exceed the eligible
                    patient’s medication and/or administration co-payment,
                    co-insurance, or deductible costs (“Patient Responsibility”)
                    for QUTENZA, whether covered under the medical or pharmacy
                    benefit. For pharmacy claims associated with the medication,
                    this offer can be used only with a valid QUTENZA
                    prescription at the time the prescription is filled by the
                    pharmacist and dispensed to the patient, and is good only at
                    participating pharmacies in the US.
                  </li>
                  <li>
                    The Program is valid for the patient’s out-of-pocket costs
                    for the medication and cannot be used if the patient is
                    eligible to be reimbursed for the entire cost of QUTENZA.
                    The patient and patient’s healthcare provider may not seek
                    any other reimbursement of Patient Responsibility for the
                    medication.
                  </li>
                  <li>
                    The Program is valid for the patient’s total out-of-pocket
                    costs for the administration of QUTENZA and cannot be used
                    if the patient is eligible to be reimbursed for the cost of
                    the administration of QUTENZA. The patient and the patient’s
                    healthcare provider may not seek other reimbursement of
                    Patient Responsibility for the administration of QUTENZA.
                    Applications for the full refund for the administration of
                    QUTENZA are not eligible for the Program and will not be
                    approved if the healthcare provider’s administration costs
                    are not covered or reimbursed by the patient’s insurance.
                  </li>
                  <li>
                    Patient Responsibility for the medication must be isolated
                    on the claim and separate from other services and products.
                    A patient may not apply for reimbursement of Patient
                    Responsibility under the Program if the patient’s healthcare
                    provider has already sought reimbursement under the Program,
                    and the patient’s healthcare provider may not seek such
                    reimbursement of Patient Responsibility under the Program if
                    the patient has already applied for reimbursement under the
                    Program.
                  </li>
                  <li>
                    Refunds will be processed in the order in which they are
                    received. Approved claims will be processed and paid in the
                    subsequent billing cycle. Please allow approximately 4 weeks
                    for delivery of refund checks. Tampering with, altering, or
                    falsifying payment information is prohibited by law.
                  </li>
                  <li>
                    The Program is effective as of January 1, 2024, for
                    treatments administered after this date. This offer is
                    limited to 1 per person, is nontransferable, and is valid
                    for the eligible patient only. No other purchase is
                    necessary. This offer has no cash value and cannot be
                    combined with any other patient assistance program, free
                    trial, discount, prescription savings card, or other offer.
                    Averitas Pharma, Inc., reserves the right to cancel, modify,
                    or rescind this Program at any time. Aggregate and
                    non-identiﬁable patient information may be used by Averitas
                    Pharma, Inc., for market research and other related
                    purposes. This Program is not insurance and is not intended
                    to substitute for insurance. This offer is void where
                    prohibited or restricted by law.
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
